import { UcDomains } from '@usercentrics/cmp-browser-sdk/dist/src/interfaces';

import {
  CONSENT_STATUS_CHANGE,
  ConsentManagerType,
  ConsentState,
  UC_UI_INITIALIZED,
  UserCentricsServiceName,
} from './ConsentManager.types';
import { ServiceData, UCConsentEvent } from './UsercentricsUiAPIV3.types';

const UC_UI_INITIALIZATION_TIMEOUT = 5 * 1000;

export class ConsentManager implements ConsentManagerType {
  private useFallback = false;
  private initializationPromise?: Promise<void>;
  private fallbackConsent: Record<string, boolean | undefined> = {};
  private prevServicesConsent: Record<string, ServiceData> | undefined;
  private currServicesConsent: Record<string, ServiceData> | undefined;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    if (!this.initializationPromise) {
      this.initializationPromise = this.initializeUC().catch(() => this.initFallback());

      window.addEventListener(UC_UI_INITIALIZED, this.setUpUcHandler);
    }

    return this.initializationPromise;
  }

  async isInitialized(): Promise<boolean> {
    return this.useFallback || Boolean(window.__ucCmp && window.__ucCmp.isInitialized());
  }

  async getConsentStatus(serviceName: string, changeEvent?: CustomEvent): Promise<boolean | undefined> {
    if (this.useFallback) {
      return this.getFallbackConsentStatus(serviceName, changeEvent);
    }

    return await this.getUcConsentStatus(serviceName, changeEvent);
  }

  async getControllerId(): Promise<string | undefined> {
    if (this.useFallback || !window.__ucCmp) {
      return;
    }

    return window.__ucCmp.getControllerId();
  }

  updateConsentStatus(serviceName: string, isAccepted: boolean): Promise<void> {
    if (this.useFallback) {
      this.fallbackConsent[serviceName] = isAccepted;

      return this.sendConsentUpdateEvent();
    }

    return this.updateUcConsentStatus(serviceName, isAccepted);
  }

  private async setUpUcHandler() {
    const consentDetails = await window.__ucCmp?.getConsentDetails();

    this.currServicesConsent = structuredClone(consentDetails?.services);

    window.addEventListener(CONSENT_STATUS_CHANGE, (event: UCConsentEvent) => {
      this.prevServicesConsent = structuredClone(this.currServicesConsent);
      this.currServicesConsent = structuredClone(event.detail.services);

      if (!this.prevServicesConsent || !this.currServicesConsent) {
        return;
      }

      // non essential consent was withdrawn
      if (
        Object.entries(this.prevServicesConsent).some(
          ([id, service]) =>
            !service.essential && service.consent?.given && !this.currServicesConsent?.[id]?.consent?.given,
        )
      ) {
        location.reload();
      }
    });
  }

  private async getService(serviceName: string): Promise<{ service: ServiceData; serviceId: string } | undefined> {
    if (!window.__ucCmp) {
      return;
    }

    const services = (await window.__ucCmp.getConsentDetails()).services;

    for (const [serviceId, service] of Object.entries(services)) {
      if (service.name === serviceName) {
        return { serviceId, service };
      }
    }

    return;
  }

  private async updateUcConsentStatus(serviceName: string, isAccepted: boolean): Promise<void> {
    if (!window.__ucCmp) {
      return Promise.reject(new Error(`${serviceName} Consent update failed: usercentrics not initialized`));
    }

    const { serviceId } = (await this.getService(serviceName)) || {};

    if (!serviceId) {
      return Promise.reject(new Error(`${serviceName} Consent update failed: Service not found`));
    }

    return window.__ucCmp.updateServicesConsents({ id: serviceId, consent: isAccepted });
  }

  private async initializeUC(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (window.__ucCmp) {
        resolve();

        return;
      }

      // eslint-disable-next-line prefer-const

      const timeout = setTimeout(() => {
        window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
        reject(new Error('failed to load usercentrics UI'));
      }, UC_UI_INITIALIZATION_TIMEOUT);

      const onInitializedCallback = (): void => {
        if (window.__ucCmp) {
          clearTimeout(timeout);
          window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
          resolve();
        }
      };

      window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);
    });
  }

  private async getUcConsentStatus(serviceName: string, changeEvent?: CustomEvent): Promise<boolean | undefined> {
    const consentStatus = changeEvent?.detail[serviceName];

    if (typeof consentStatus === 'boolean') {
      return consentStatus;
    }

    const { service } = (await this.getService(serviceName)) || {};

    return service?.consent?.given;
  }

  private getFallbackConsentStatus(serviceName: string, changeEvent?: CustomEvent): boolean | undefined {
    const consentStatus = changeEvent?.detail[serviceName];

    if (typeof consentStatus === 'boolean') {
      return consentStatus;
    }

    return this.fallbackConsent[serviceName] ?? false;
  }

  private sendConsentUpdateEvent(): Promise<void> {
    window.dispatchEvent(new CustomEvent(CONSENT_STATUS_CHANGE, { detail: this.fallbackConsent }));

    return Promise.resolve();
  }

  private async initFallback(): Promise<void> {
    this.useFallback = true;

    const onInitializedCallback = (): void => {
      if (window.__ucCmp) {
        window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
        this.useFallback = false;
      }
    };

    window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);

    return this.sendConsentUpdateEvent();
  }
}

/**
 * Each bundle should (in case of an ad blocker with usercentrics.eu in its filter list) use the same instance of the
 * Usercentrics Cache, that's why we store it in the window object. Otherwise, controllers that are bundled
 * separately (e.g. ConsentController or YouTubeController) will create their own instance of the singleton.
 */

if (typeof window !== 'undefined' && !window.consentManager) {
  window.UC_UI_DOMAINS = {
    crossDomainConsentSharingIFrame: 'https://www.lichtblick.de/cross-domain-bridge.html',
  } as UcDomains;

  window.consentManager = new ConsentManager();
}

const global = typeof window !== 'undefined' ? window : ({} as Partial<Window>);

export const getConsentState = async (
  service: UserCentricsServiceName,
  changeEvent?: CustomEvent,
): Promise<ConsentState> => {
  const isConsentGiven = await global.consentManager?.getConsentStatus(service, changeEvent);

  switch (isConsentGiven) {
    case true:
      return ConsentState.Given;
    case false:
      return ConsentState.Denied;
    default:
      return ConsentState.Unknown;
  }
};

export const setConsent = (service: UserCentricsServiceName, status: ConsentState.Given | ConsentState.Denied) =>
  global.consentManager?.updateConsentStatus(service, status === ConsentState.Given) || Promise.resolve();

export const getControllerId = () => global.__ucCmp?.getControllerId();
