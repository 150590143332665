import Script from 'next/script';
import { FC } from 'react';

export const ConsentScript: FC = () => (
  <>
    <Script
      async
      data-settings-id={process.env.NEXT_PUBLIC_USERCENTRICS_ID}
      id="usercentrics-cmp"
      src="https://web.cmp.usercentrics.eu/ui/loader.js"
    />
  </>
);
