/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { useDecision } from '@optimizely/react-sdk';
import { useEffect } from 'react';

import {
  ConsentState,
  DATADOG_RUM_NAME,
  OPTIMIZELY_NAME,
} from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsentState } from '@lichtblick/consent/hooks/useConsentState';
import { useTracker } from '@lichtblick/tracker';

export const useOptimizelyFeatureFlag = (flag?: string) => {
  // To avoid calling the Optimizely useDecision hook with an empty string (which logs an error), we return early here.
  // Since the flag value won't change during runtime, the order of hooks during rendering remains the same.
  if (!flag) {
    return true;
  }

  const [_optimizelyConsentState] = useConsentState(OPTIMIZELY_NAME);
  const [datadogRumState] = useConsentState(DATADOG_RUM_NAME);

  const { trackOptimizelyDecisionEvent } = useTracker();

  const [{ enabled, flagKey, ruleKey, variationKey }] = useDecision(flag, { autoUpdate: true });

  useEffect(() => {
    if (!flagKey || !variationKey || !ruleKey) {
      return;
    }

    trackOptimizelyDecisionEvent({ flagKey, ruleKey, variationKey });

    if (datadogRumState === ConsentState.Given) {
      import('@datadog/browser-rum').then(({ datadogRum }) => {
        datadogRum.onReady(() => {
          datadogRum.addFeatureFlagEvaluation(flag, enabled);

          datadogRum.setGlobalContextProperty('featureFlags', {
            ...(datadogRum.getGlobalContext()['featureFlags'] as Record<string, boolean>),
            [flag]: enabled,
          });
        });
      });
    }
  }, [datadogRumState, enabled, flag, flagKey, ruleKey, trackOptimizelyDecisionEvent, variationKey]);

  return enabled;
};
