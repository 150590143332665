import { Service } from '@usercentrics/cmp-browser-sdk';

import { UsercentricsUiAPIV3 } from './UsercentricsUiAPIV3.types';

export const CONSENT_STATUS_CHANGE = 'UC_CONSENT';
export const UC_UI_INITIALIZED = 'UC_UI_INITIALIZED';

export const GOOGLE_MAPS_NAME = 'Google Maps';
export const GOOGLE_ANALYTICS_NAME = 'Google Analytics';
export const GOOGLE_TAG_MANAGER_NAME = 'Google Tag Manager';
export const YOUTUBE_NAME = 'YouTube Video';
export const AKLAMIO_NAME = 'Aklamio';
export const DATADOG_RUM_NAME = 'Datadog Real User Monitoring';
export const OPTIMIZELY_NAME = 'Optimizely';

export type UserCentricsServiceName =
  | typeof GOOGLE_MAPS_NAME
  | typeof GOOGLE_ANALYTICS_NAME
  | typeof GOOGLE_TAG_MANAGER_NAME
  | typeof YOUTUBE_NAME
  | typeof AKLAMIO_NAME
  | typeof OPTIMIZELY_NAME
  | typeof DATADOG_RUM_NAME;

export type UsercentricsDomains = {
  crossDomainConsentSharingIFrame: string;
};

export type ConsentManagerType = {
  getConsentStatus(serviceName: string, changeEvent?: CustomEvent): Promise<boolean | undefined>;
  getControllerId(): Promise<string | undefined>;
  init(): Promise<void>;
  isInitialized(): Promise<boolean>;
  updateConsentStatus(serviceName: string, isAccepted: boolean): Promise<void>;
};

export enum ConsentState {
  Given = 'given',
  Unknown = 'unknown',
  Denied = 'denied',
  Error = 'error',
}

/** When giving consent using the API (instead of customer clicking the Dialog),
 * consent can be either explicit (e.g. when clicking some custom button) or implicit. */
export enum ConsentType {
  Explicit = 'explicit',
  Implicit = 'implicit',
}

/**
 * Typing for the main `window.UC_UI` API used for integration
 */

// Generated by https://quicktype.io

export type ServiceBaseInfo = {
  categorySlug: CategorySlug;
  consent: Consent;
  description: string;
  id: string;
  isEssential: boolean;
  isHidden: boolean;
  legalBasis: string[];
  name: string;
  processorId: string;
  subServices: any[];
  subServicesLength: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  usesThirdCountry: boolean;
  version: string;
};

export type CategorySlug = 'essential' | 'functional' | 'marketing';

export type Consent = {
  history: ConsentHistory[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  status: boolean;
};

export type ConsentHistory = {
  action: Action;
  language: 'de';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  status: boolean;
  timestamp: number;
  type: string;
  versions: Versions;
};

export type Action = 'onDenyAllServices' | 'onAcceptAllServices' | 'onUpdateServices' | 'onInitialPageLoad';

export type Versions = {
  application: string;
  service: string;
  settings: string;
};

export type UsercentricsUiAPIV2 = {
  /**
   * A method for accepting a single service.
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=acceptservice
   */
  acceptService: (serviceId: string, consentType?: ConsentType) => Promise<void>;

  getControllerId: () => string;
  /**
   * A method to get array of all services with their basic information
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
   */
  getServicesBaseInfo: () => ServiceBaseInfo[];

  /**
   * A method to get array of all services with their full information.
   * An extra api request will be made, therefore the return represents
   * the eventual completion (or failure) of an asynchronous operation
   * and its returning value.
   *
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesfullinfo
   */
  getServicesFullInfo: () => Promise<Service[]>;

  /**
   * A method to check if app is initialized or not
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=isinitialized
   */
  isInitialized: () => boolean;

  /**
   * A method for rejecting a single service.
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=rejectservice
   */
  rejectService: (serviceId: string) => Promise<void>;

  /**
   * Programmatic way to show First Layer.
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showfirstlayer
   */
  showFirstLayer: () => void;

  /**
   * Programmatic way to show Second Layer. If a service/vendor Id value is passed,
   * Second Layer will open the right tab, scroll to the given service/vendor entry and expand it.
   * If no Id is passed, Second Layer will be shown without srcolling to any specific service/vendor.
   *
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showsecondlayer
   */
  showSecondLayer: (serviceId?: string) => void;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    UC_UI_SUPPRESS_CMP_DISPLAY: boolean;
    __ucCmp?: UsercentricsUiAPIV3;
    consentManager: ConsentManagerType;
  }
}
