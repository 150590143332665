'use client';

import { useState, useEffect } from 'react';

import { logger } from '@lichtblick/logger';

import {
  ConsentState,
  CONSENT_STATUS_CHANGE,
  getConsentState,
  setConsent,
  UserCentricsServiceName,
} from '../ConsentManager';

export const useConsentState = (
  serviceName: UserCentricsServiceName,
): [ConsentState, (status: ConsentState.Given | ConsentState.Denied) => Promise<void>] => {
  const [consentState, setConsentState] = useState<ConsentState>(ConsentState.Unknown);

  const setServiceConsent = (state: ConsentState.Given | ConsentState.Denied): Promise<void> => {
    setConsentState(state);

    return setConsent(serviceName, state);
  };

  useEffect(() => {
    const fetchInitialConsent = async () => {
      const consent = await getConsentState(serviceName);

      setConsentState(consent);
    };

    fetchInitialConsent();
  }, [serviceName]);

  useEffect(() => {
    const handleConsentChange = (event: any): void => {
      getConsentState(serviceName, event)
        .then((currentState) => {
          setConsentState(currentState);
        })
        .catch((error) => {
          logger.error('Error handling consent change:', error);
        });
    };

    window.addEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);

    return () => window.removeEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);
  }, [serviceName]);

  return [consentState, setServiceConsent];
};
