import { datadogLogs } from '@datadog/browser-logs';

const stage = process.env.NEXT_PUBLIC_STAGE;
const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '';
const service = process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME || 'frontend-next';
const proxyUrl = process.env.NEXT_PUBLIC_DATADOG_PROXY_URL || 'https://logging.lichtblick.app/proxy';
const versionInfo = process.env.NEXT_PUBLIC_GIT_COMMIT_REF ? { version: process.env.NEXT_PUBLIC_GIT_COMMIT_REF } : {};

if (clientToken && typeof window !== 'undefined') {
  datadogLogs.init({
    clientToken,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service,
    env: stage,
    proxy: (options) => `${proxyUrl}${options.path}?${options.parameters}`,
    beforeSend: ({ message, status }) => {
      if (status === 'error' && message?.includes(`Fetch error POST ${process.env.NEXT_PUBLIC_GRAPHQL_SERVER}`)) {
        return false;
      }

      if (['frontend-website', 'frontend-checkout-b2c'].includes(service) && message?.includes('[OPTIMIZELY]')) {
        return false;
      }

      return true;
    },
    ...versionInfo,
  });
}

export const prodLogger = typeof window !== 'undefined' ? datadogLogs.logger : console;
