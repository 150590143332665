'use client';

import { useState, useEffect } from 'react';

import { UC_UI_INITIALIZED, getControllerId } from '../ConsentManager';

export const useUsercentricsControllerId = (): string | undefined => {
  const [controllerId, setControllerId] = useState<string>();

  useEffect(() => {
    const onInitializedCallback = (): void => {
      getControllerId()?.then((id) => setControllerId(id));
    };

    window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);

    return () =>
      window.removeEventListener(UC_UI_INITIALIZED, () => getControllerId()?.then((id) => setControllerId(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return controllerId;
};
