import { FC, MouseEventHandler, useContext } from 'react';

import { ConsentState, YOUTUBE_NAME, useConsentState } from '@lichtblick/consent';
import { LegacyButton, Headline } from '@lichtblick/ui-components';

import { DataPrivacyLayer, Iframe, Root, PrivacyText } from './MYoutube.styles';

import { SettingsContext } from '../../helpers/settingsContext';
import { MYoutubeType } from '../../types/storyblok';
import { AButton } from '../AButton';

const YOUTUBE_CONFIG = {
  autoplay: '0',
  showinfo: '0',
  rel: '0',
  modestbranding: '1',
  playsinline: '1',
};

const openUsercentricsSecondLayer: MouseEventHandler<Element> = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.__ucCmp?.showSecondLayer();
};

export const MYoutube: FC<MYoutubeType> = ({ id }) => {
  const [youtubeConsentState] = useConsentState(YOUTUBE_NAME);

  const { youTubePrivacyButton, youTubePrivacyHeadline, youTubePrivacyLink, youTubePrivacyText } =
    useContext(SettingsContext);

  const privacyLayer = (
    <DataPrivacyLayer>
      {youTubePrivacyHeadline && (
        <Headline renderAs="h2" size="S">
          {youTubePrivacyHeadline}
        </Headline>
      )}
      {youTubePrivacyText && <PrivacyText>{youTubePrivacyText}</PrivacyText>}
      {youTubePrivacyButton && (
        <LegacyButton onClick={openUsercentricsSecondLayer} variant="secondary">
          {youTubePrivacyButton}
        </LegacyButton>
      )}
      {youTubePrivacyLink?.[0] && <AButton {...youTubePrivacyLink[0]} isSlim={true} variant={'link'} />}
    </DataPrivacyLayer>
  );

  const videoIframe = (
    <Iframe src={`https://www.youtube-nocookie.com/embed/${id}?${new URLSearchParams(YOUTUBE_CONFIG)}`} />
  );

  return (
    <Root data-testid="youtube">
      {youtubeConsentState !== ConsentState.Unknown &&
        (youtubeConsentState === ConsentState.Given ? videoIframe : privacyLayer)}
    </Root>
  );
};
